@import './../../styles/customMediaQueries.css';
@import './../../styles//marketplaceDefaults.css';

.root {}

.quantityLabel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.input {
    padding-bottom: 20px;
}

.submitButton {
    background-color: var(--marketplaceColor);

    &:hover,
    &:focus {
        background-color: var(--marketplaceColorDark);
    }
}

.inProgress {
    background-color: var(--marketplaceColorDark);

    &:disabled {
        background-color: var(--marketplaceColorDark);
    }
}

.error {
    color: var(--colorFail);

    padding: 24px 0;

    @media (--viewportMedium) {
        padding: 0;
    }
}

.btnWrapper {
    display: flex;
    justify-content: space-between;
}

.yesButton,
.noButton {
    width: 48%;
}

.confirmText {
    padding: 20px 0;
}